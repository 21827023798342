.router-flex {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.page-flex {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
}
